'use client'

import {hasRequiredRoles} from "@/lib/tools/auth"
import {Account} from "@/lib/types"
import classNames from "classnames"
import Link from "next/link"
import {usePathname} from "next/navigation"
import {useEffect, useState} from "react"
import {Icon} from "@/components/atoms/icons";
import {MdExpandLess, MdExpandMore} from "react-icons/md";

export interface MenuItem {
  name?: string,
  href: string,
  key: string,
  roles?: string[]
  expanded?: boolean
  items?: MenuItem[]
}

export const MenuLink = (props: any) => <Link {...props}
                                              className={classNames("block p-2 hover:bg-gray-200 rounded", props.className)}/>

export const CustomMenu = ({lang, profile, items: initial}: {
  lang?: string,
  profile: Account | undefined,
  items: MenuItem[]
}) => {
  const [items, setItems] = useState<MenuItem[]>(initial)
  const [activePathPrefix, setActivePathPrefix] = useState('/')
  const pathname = usePathname();

  useEffect(() => {
    const p = pathname.split('/')
    let active = ''
    if (p && p.length == 1) {
      active = p[0]
    } else if (p && p.length > 1) {
      active = p[0] + '/' + p[1]
    }
    setActivePathPrefix(active)
  }, [pathname])

  const toggleExpandItem = (itemPos: any) => {
    items[itemPos].expanded = !items[itemPos].expanded
    setItems([...items])
  }

  const isActive = (it: MenuItem, exact?: boolean) => {
    return pathname != "/" && it.href.startsWith(activePathPrefix)
  }

  return <header className="pl-4 md:pl-2 w-full">
    <ul>
      <li><MenuLink href={"/"} passHref={true}>Accueil</MenuLink></li>
    </ul>

    <hr className="border-t-gray-300  m-1 mx-auto w-40 "/>

    <h4 className="text-lg pt-2 font-bold">Thématiques</h4>
    <ul>
      <li>
        {items && items.map((it, i) => {
          const allowed = it.roles ? profile && hasRequiredRoles(it.roles, profile.roles ?? []) : true
          return allowed && <div key={'m-i-' + it.name}>
              <div className="flex flex-row w-full justify-end">
                  <div className="grow"><MenuLink href={it.href} passHref={true}
                                                  className={classNames({'bg-blue-100': isActive(it)})}>{it.name}</MenuLink>
                  </div>
                {it.items && it.items.length > 0 &&
                    <div className="flex-none p-2 w-10 text-center cursor-pointer hover:bg-gray-200 rounded"
                         onClick={() => toggleExpandItem(i)}>{it.expanded ? <Icon icon={MdExpandMore}/> :
                      <Icon icon={MdExpandLess}/>}</div>}
              </div>
              <ul>
                {(it.expanded || isActive(it)) && it.items && it.items.map(sit => {
                  const sallowed = sit.roles ? profile && hasRequiredRoles(sit.roles, profile.roles ?? []) : true
                  return sallowed && <li key={'m-s-i-' + sit.name}><MenuLink href={sit.href} className="pl-4"
                                                                             passHref={true}>{sit.name}</MenuLink></li>
                })}
              </ul>
          </div>
        })}
      </li>
    </ul>
    <hr className="border-t-gray-300  m-1 mx-auto w-40 "/>
    <h4 className="text-lg pt-2 font-bold">Ressources</h4>
    <ul>
      <li><MenuLink href={"/pages/contact-presse"} passHref={true}>Presse</MenuLink></li>
      <li><MenuLink href={"/pages/a-propos"} passHref={true}>A propos</MenuLink></li>
      <li></li>
      <li>

      </li>

    </ul>
    <p className="text-xs text-gray-500 text-center pt-3">
      <MenuLink className="text-xs text-gray-500 inline-block text-sm pl-2" title="Tarifs"
                href="/pages/pricing">Tarifs</MenuLink>
      <MenuLink className="text-xs text-gray-500 inline-block text-sm pl-2" title="Conditions Générales d'Utilisation"
                href="/pages/conditions-generales-utilisation">CGU</MenuLink>
      <MenuLink className="text-xs text-gray-500 inline-block text-sm pr-2" title="Conditions Générales de Vente"
                href="/pages/conditions-generales-vente">CGV</MenuLink><br/>
      &copy; 2024 Les Petites Patounes <br/> Tous droits réservés <br/>
    </p>
  </header>
}
